<template>
  <div>
    <form-title :text="$t('title.select_day_month')" />
    <form-content>
      <div>
        <div v-for="item in months" :key="item.name">
          <h2
            v-text="item.name"
            class="text-lg text-center font-headings mb-4 mt-8 sm:mt-12 italic  capitalize 
        "
          />
          <div class="flex flex-wrap -mx-1 mb-6">
            <div
              class="px-1 mb-2 w-3/12 sm:w-2/12 lg:w-1/12"
              v-for="day in item.days"
              :key="day"
            >
              <btn-choose
                type="button"
                @clicked="
                  $emit('update-user-data', {
                    birthDay: day,
                    birthMonth: item.name,
                  })
                "
                v-text="day"
              />
            </div>
          </div>
        </div>
      </div>
    </form-content>
  </div>
</template>

<script>
import BtnChoose from "../Ui/BtnChoose.vue";
import FormContent from "./FormContent.vue";
import FormTitle from "./FormTitle.vue";
import i18n from "@/i18n.js";
export default {
  components: { FormTitle, BtnChoose, FormContent },
  name: "SelectDayAndMonth",

  methods: {
    populateMonthArray(start, end) {
      return Array(parseInt(end) - parseInt(start) + 1)
        .fill()
        .map((_, index) => parseInt(start) + index);
    },
  },
  computed: {
    months() {
      const zodiacSigns = this.$store.state.zodiacSigns;
      const selectedSign = this.$store.state.userData.zodiacSign;

      if (!selectedSign) return;
      const sign = zodiacSigns.filter((item) => item.name === selectedSign)[0];

      const [startMonth, startDay] = sign.startDate.split(".");
      const startMonthDate = new Date(
        new Date(2000, startMonth - 1, startDay, 0, 0, 0)
      );
      const startMonthAllDays = new Date(2000, startMonth, 0).getDate();

      const [endMonth, endDay] = sign.endDate.split(".");
      const endMonthDate = new Date(
        new Date(2000, endMonth - 1, endDay, 0, 0, 0)
      );
      const endMonthAllDays = new Date(2000, endMonth, endDay).getDate();
      const signAllDays = [
        {
          name: startMonthDate.toLocaleString(i18n.t("date_format"), {
            month: "long",
          }),
          days: this.populateMonthArray(startDay, startMonthAllDays),
        },

        {
          name: endMonthDate.toLocaleString(i18n.t("date_format"), {
            month: "long",
          }),
          days: this.populateMonthArray(1, endMonthAllDays),
        },
      ];

      return signAllDays;
    },
  },
};
</script>
